<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="1">未处理({{table.total_1}})</el-radio-button>
      <el-radio-button :label="2">已处理({{table.total_2}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="举报账号:" prop="account">
          <el-input clearable v-model="table.params.account" placeholder="请输入举报账号"></el-input>
        </el-form-item>
        <el-form-item label="被举报账号:" prop="object_account">
          <el-input clearable v-model="table.params.object_account" placeholder="请输入被举报账号"></el-input>
        </el-form-item>
        <el-form-item label="举报日期:" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="举报类型:" prop="report_type">
          <el-select v-model="table.params.report_type" clearable placeholder="请选择举报类型">
            <el-option
              v-for="item in reportType"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="table.params.status=== 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:status="{row}">
        <span>{{statusOpt[row.status]}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button v-if="row.order_id !== 0" type="text" @click="showEdit(row)">查看工单</el-button>
        <el-button type="text" icon="el-icon-view" @click="showDetail(row, table.params.status)">处理</el-button>
      </template>
    </VTable>

    <Order ref="order"></Order>
    <detail ref="detail" @refresh="getTable"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Order from './components/Order.vue';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ReportList',
  components: {
    VTable,
    Order,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "user_name", label: "举报用户", hidden: false },
        { name: "user_account", label: "举报账号", hidden: false },
        { name: "object_name", label: "被举报用户", hidden: false },
        { name: "object_account", label: "被举报账号", hidden: false },
        { name: "create_time", label: "举报时间", hidden: false },
        { name: "report_type", label: "举报类型", hidden: false },
        { name: "title", label: "零工标题", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      field2: [
        { name: "user_name", label: "举报用户", hidden: false },
        { name: "user_account", label: "举报账号", hidden: false },
        { name: "object_name", label: "被举报用户", hidden: false },
        { name: "object_account", label: "被举报账号", hidden: false },
        { name: "create_time", label: "举报时间", hidden: false },
        { name: "report_type", label: "举报类型", hidden: false },
        { name: "title", label: "零工标题", hidden: false },
        { name: "status", label: "处理结果", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        total_1: '',
        total_2: '',
        params: {
          date: [],
          status: 1, // 状态 1:未处理 2:已处理
          account: '',
          object_account: '',
          start_time: '',
          end_time: '',
          report_type: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      statusOpt:{
        1:'未处理',
        2:'不予处理',
        3:'警告',
        4:'封号'
      },
      reportType: [], // 举报类型
    }
  },
  created() {
    this.getReportType();
    this.getTable();
  },
  methods: {
    // 获取举报类型
    getReportType() {
      this.$http.get('/common/constant/report_type', {params:{}}).then(res => {
        if(res.code === 1) {
          this.reportType = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getTable() {
      let _date = this.table.params.date;
      console.log(this.table.params.date)
      this.table.params.start_time = _date ? _date[0] : '';
      this.table.params.end_time = _date ? _date[1] : '';
      let _params = {
        account:this.table.params. account,
        object_account: this.table.params.object_account,
        report_type:this.table.params.report_type,
        status: this.table.params.status,
        start_time: this.table.params.start_time,
        end_time: this.table.params.end_time,
        page: this.table.params.page,
        count:this.table.params.count,
      }
      this.$http.get('/admin/flex_report/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.order
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 查看
    showDetail(row, curStatus) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row, curStatus)
      dom = null
    },
    // 关闭
    handleClose() {
      this.$confirm("是否关闭此条记录？", "关闭", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/article/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    }
  }
}
</script>
